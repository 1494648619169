var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ((_vm.$route.meta.title) + " " + (_vm.loading ? '' : ("(" + (this.data.length) + ")")))
          )}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.login.permissions.insured_categories.read)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"pink","to":{
                name: 'insured_categories',
              }}},on),[_c('v-icon',[_vm._v(" mdi-account-switch ")])],1)]}}],null,false,2068318986)},[_c('span',{domProps:{"textContent":_vm._s('Asegurados categorias')}})]):_vm._e(),(_vm.login.permissions.insureds.read)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"purple","to":{
                name: 'contratantes.refound',
              }}},on),[_c('v-icon',[_vm._v(" mdi-account ")])],1)]}}],null,false,409801487)},[_c('span',{domProps:{"textContent":_vm._s('Asegurados Reembolso')}})]):_vm._e(),_c('BtnCircle',{attrs:{"tLabel":"Concentradores","tPosition":"bottom","icon":"mdi-group","color":"teal darken-3","size":"x-small","to":{ name: (_vm.page_route + ".concentradores") }}}),(_vm.login.permissions.contractors.create)?_c('BtnCircle',{attrs:{"tLabel":"Crear","tPosition":"bottom","icon":"mdi-plus","color":"success","size":"x-small","to":{ name: (_vm.page_route + ".store") }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"10"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.active_options,"item-value":"active","item-text":function (v) { return v.name; },"dense":""},on:{"change":_vm.getData},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.user_id ? 'success' : ''},domProps:{"textContent":_vm._s(item.user_id ? 'mdi-check' : 'mdi-close')}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(_vm.$store.getters.getLogin.permissions.insureds.read)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","x-small":"","color":"warning","to":{
                    name: 'asegurados',
                    params: { contractor_id: item.id },
                  }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-clipboard-account')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Asegurados')}})]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.handleView(item.id)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-eye-outline')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${loading ? '' : `(${this.data.length})`}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.insured_categories.read" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="pink"
                :to="{
                  name: 'insured_categories',
                }"
              >
                <v-icon> mdi-account-switch </v-icon>
              </v-btn>
            </template>
            <span v-text="'Asegurados categorias'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.insureds.read" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                color="purple"
                :to="{
                  name: 'contratantes.refound',
                }"
              >
                <v-icon> mdi-account </v-icon>
              </v-btn>
            </template>
            <span v-text="'Asegurados Reembolso'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Concentradores"
            tPosition="bottom"
            icon="mdi-group"
            color="teal darken-3"
            size="x-small"
            :to="{ name: `${page_route}.concentradores` }"
          />
          <BtnCircle
            v-if="login.permissions.contractors.create"
            tLabel="Crear"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{ name: `${page_route}.store` }"
          />
        </v-col>
        <v-col cols="12" xs="12" md="10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <v-select
            v-model="active"
            :items="active_options"
            item-value="active"
            :item-text="(v) => v.name"
            dense
            @change="getData"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.user_id="{ item }">
              <v-icon
                small
                v-text="item.user_id ? 'mdi-check' : 'mdi-close'"
                :color="item.user_id ? 'success' : ''"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip
                left
                v-if="$store.getters.getLogin.permissions.insureds.read"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    :to="{
                      name: 'asegurados',
                      params: { contractor_id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-clipboard-account'" />
                  </v-btn>
                </template>
                <span v-text="'Asegurados'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    @click="handleView(item.id)"
                  >
                    <v-icon v-text="'mdi-eye-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Ver'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import { index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contratantes",
      login: this.$store.getters.getLogin,
      loading: true,
      data: [],
      search: "",
      headers: [
        {
          text: "Nombre",
          filterable: true,
          value: "name",
        },
        { text: "Asegurados", filterable: false, value: "insureds_total" },
        { text: "Usr. sis.", filterable: false, value: "user_id" },
        { text: "Acciones", value: "action", sortable: false },
      ],
      active: 1,
      active_options: [
        {
          active: 1,
          name: "Activo",
        },
        {
          active: 0,
          name: "Inactivo",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.data = [];

      index("contractors", this.login.token, [
        {
          name: "active",
          value: this.active,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.data = response.data;
        this.loading = false;
      });
    },
    handleView(row_id) {
      this.$router.push({
        name: `${this.page_route}.view`,
        params: { id: row_id },
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>